import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

const ProjectsPage = ({ data }) => {
  return (
    <Layout title="Projects">
      <SEO title="Projects | Leonie Freudenberger" />
      <article className="tabbed-content">
        <div className="video-content">
          {data.allContentfulProjects.nodes.map(project => {
            return (
              <div className="entry fade-in-1">
                {project.image !== null && (
                  <div className="project-image">
                    <Img
                      fluid={project.image.localFile.childImageSharp.fluid}
                      className="project-image"
                    />
                  </div>
                )}
                <div className="details">
                  <h4>{project.title}</h4>
                  {project.description && (
                    <div
                      className="credits"
                      dangerouslySetInnerHTML={{
                        __html: project.description.childMarkdownRemark.html,
                      }}
                    ></div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulProjects {
      nodes {
        title
        description {
          childMarkdownRemark {
            html
          }
          description
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
